.fab-landing {
    height: calc(100vh - 72px);
    display: flex;
    align-content: center;
    align-items: center;
    background-color: black;
    @media only screen and (max-width: 600px) {
        height: 1024px; }

    .stack {
        position: relative;
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 40px;
        z-index: 2;
        margin: 0 auto;
        text-align: center;
        font-feature-settings: 'liga' off, 'clig' off;
        color: #fff;
        width: 100%;
        max-width: 850px;
        @media only screen and (max-width: 1920px) {
            max-width: 533px; }
        @media only screen and (max-width: 720px) {
            max-width: 435px; }
        @media only screen and (max-width: 600px) {
            max-width: 328px; }
        @media only screen and (max-width: 390px) {
            max-width: 294px; } }

    .logo {
        width: 109px;
        margin: 0 auto; }

    h1 {
        font-family: "Inter Tight";
        font-size: 64px;
        font-weight: 900!important;
        line-height: 64px!important;
        .blue {
            color: #26bbff; }
        @media only screen and (max-width: 1280px) {
            font-size: 52px;
            line-height: 52px!important; }
        @media only screen and (max-width: 600px) {
            font-size: 40px;
            line-height: 40px!important; } }

    p {
        font-family: Inter;
        font-size: 20px;
        font-weight: 400!important;
        line-height: 33px;
        @media only screen and (max-width: 1280px) {
            font-size: 16px;
            line-height: 26.4px;
            letter-spacing: 0.16px; }
        @media only screen and (max-width: 600px) {
            font-size: 14px;
            line-height: 23.1px;
            letter-spacing: 0.28px; }
        a.fab.read-more {
            color: #26bbff!important;
            &:hover {
                color: #72D3FF!important; } } }

    a.fab-button {
        display: flex;
        height: 48px;
        width: fit-content;
        padding: 0px 20px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 10px;
        background: #26BBFF;
        &:hover {
            background: #72D3FF!important; }
        &:focus, &:focus-visible {
            outline: 2px solid #fff !important;
            outline-offset: 2px; }
        span {
            color: #000!important;
            font-family: Inter;
            font-size: 14px;
            font-weight: 500!important;
            line-height: 21px;
            letter-spacing: 0.28px; }
        i {
            color: #000; }
        @media only screen and (max-width: 600px) {
            height: 40px;
            padding: 0px 16px;
            border-radius: 8px; } }


    .spotlight {
        position: absolute;
        z-index: 0;
        border-radius: 1943.76px;
        opacity: 0.5;
        background: linear-gradient(132deg, rgba(67, 107, 210, 0.15) -8.28%, rgba(240, 180, 24, 0.85) 113.24%);
        transform: rotate(-30deg) translate(-50%, -50%);
        filter: blur(100px);
        top: 45%;
        left: 55%;
        width: 1944px;
        height: 1016px;
        @media only screen and (max-width: 2560px) {
            border-radius: 1522px;
            top: 45%;
            left: 60%;
            width: 1520px;
            height: 795px; }
        @media only screen and (max-width: 1920px) {
            border-radius: 1290px;
            filter: blur(78px);
            top: 45%;
            left: 55%;
            width: 1289px;
            height: 674px; }
        @media only screen and (max-width: 1280px) {
            border-radius: 1216px;
            filter: blur(65px);
            width: 1217px;
            height: 636px; }
        @media only screen and (max-width: 960px) {
            border-radius: 1039px;
            width: 1039px;
            height: 543px; }
        @media only screen and (max-width: 600px) {
            border-radius: 823px;
            top: 40%;
            width: 823px;
            height: 430px; } }

    .portal {
        z-index: 1;
        position: absolute;
        background-size: cover;
        width: 930px;
        height: 930px;
        &.left {
            left: 3%;
            top: 1%;
            background-image: url('https://us-west-2-epicgames.graphassets.com/AiKUh5PQCTaOFnmJDZJBfz/output=format:webp/jQqAsMGzReyu0fVaVyxF'); }
        &.right {
            right: 1%;
            bottom: 1%;
            background-image: url('https://us-west-2-epicgames.graphassets.com/AiKUh5PQCTaOFnmJDZJBfz/output=format:webp/4t1jFKcrRVis9YvduJvH'); }
        @media only screen and (max-width: 2560px) {
            width: 685px;
            height: 685px;
            &.left {
                left: -128px;
                top: 105px;
                background-image: url('https://us-west-2-epicgames.graphassets.com/AiKUh5PQCTaOFnmJDZJBfz/output=format:webp/resize=width:685/jQqAsMGzReyu0fVaVyxF'); }
            &.right {
                right: -107px;
                bottom: 4px;
                background-image: url('https://us-west-2-epicgames.graphassets.com/AiKUh5PQCTaOFnmJDZJBfz/output=format:webp/resize=width:685/4t1jFKcrRVis9YvduJvH'); } }
        @media only screen and (max-width: 1920px) {
            width: 477px;
            height: 477px;
            &.left {
                left: -89px;
                top: 27px;
                background-image: url('https://us-west-2-epicgames.graphassets.com/AiKUh5PQCTaOFnmJDZJBfz/output=format:webp/resize=width:477/jQqAsMGzReyu0fVaVyxF'); }
            &.right {
                right: -88px;
                bottom: 0px;
                background-image: url('https://us-west-2-epicgames.graphassets.com/AiKUh5PQCTaOFnmJDZJBfz/output=format:webp/resize=width:487/4t1jFKcrRVis9YvduJvH'); } }
        @media only screen and (max-width: 1280px) {
            width: 381px;
            height: 381px;
            &.left {
                left: -57px;
                top: 12px;
                background-image: url('https://us-west-2-epicgames.graphassets.com/AiKUh5PQCTaOFnmJDZJBfz/output=format:webp/resize=width:381/jQqAsMGzReyu0fVaVyxF'); }
            &.right {
                right: -83px;
                bottom: 0px;
                background-image: url('https://us-west-2-epicgames.graphassets.com/AiKUh5PQCTaOFnmJDZJBfz/output=format:webp/resize=width:381/4t1jFKcrRVis9YvduJvH'); } }
        @media only screen and (max-width: 960px) {
            width: 313px;
            height: 313px;
            &.left {
                left: -49px;
                top: 24px;
                background-image: url('https://us-west-2-epicgames.graphassets.com/AiKUh5PQCTaOFnmJDZJBfz/output=format:webp/resize=width:313/jQqAsMGzReyu0fVaVyxF'); }
            &.right {
                right: -61px;
                bottom: 0px;
                background-image: url('https://us-west-2-epicgames.graphassets.com/AiKUh5PQCTaOFnmJDZJBfz/output=format:webp/resize=width:313/4t1jFKcrRVis9YvduJvH'); } }
        @media only screen and (max-width: 720px) {
            &.left {
                left: -49px;
                top: 9px; }
            &.right {
                right: -41px;
                bottom: 0px; } }
        @media only screen and (max-width: 600px) {
            width: 294px;
            height: 294px;
            &.left {
                left: -49px;
                top: 2.5px;
                background-image: url('https://us-west-2-epicgames.graphassets.com/AiKUh5PQCTaOFnmJDZJBfz/output=format:webp/resize=width:294/jQqAsMGzReyu0fVaVyxF'); }
            &.right {
                right: -41px;
                bottom: 0px;
                background-image: url('https://us-west-2-epicgames.graphassets.com/AiKUh5PQCTaOFnmJDZJBfz/output=format:webp/resize=width:294/4t1jFKcrRVis9YvduJvH'); } }
        @media only screen and (max-width: 390px) {
            width: 278.5px;
            height: 278.5px; } } }
